.image-loader {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  margin: 0;
}
.image-loader img {
  width: 100%;
  object-fit: cover;
}