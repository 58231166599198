/* plans to buy list cards */
.plans-to-buy__list {
  display: flex;
}
.plans-to-buy__list__epic-plan {
  display: flex;
  flex-direction: column;
  background-color: var(--gray-100);
  border-radius: 15px;
  width: 27.778em;
  height: min-content;
  // padding-top: 2.5523em;
  padding-right: 1.806em;
  padding-left: 2.5em;
}
.plans-to-buy__list__epic-plan.epic-plan-information {
  text-align: center;
  align-items: center;
  padding: 2.5523em 1em 0 1em;
}
.plans-to-buy__list__call-to-action {
  display: flex;
  align-items: flex-end;
}

.plans-to-buy__list__epic-plan__user-icon {
  margin: 0;
  margin-right: 0.625em;
  display: flex;
  width: 1.875em;
  height: max-content;
}

.plans-to-buy__list__epic-plan.individual-plan {
  margin-right: 3.8544em;
}
.plans-to-buy__list__epic-plan__title-container {
  display: flex;
  align-items: center;
  margin-top: 2.17em;
  margin-bottom: 1.72em;
}
.plans-to-buy__list__epic-plan__content {
  width: 100%;
}
.plans-to-buy__list__epic-plan__title {
  font-family: 'Zen Dots';
  font-weight: 400;
}
.plans-to-buy__list__epic-plan__title strong {
  font-family: inherit;
  font-weight: 400;
}
.plans-to-buy__list__epic-plan__description {
  font-size: .973rem;
  margin: 0;
  line-height: 1.362em;
}
.plans-to-buy__list__epic-plan__description__main-text {
  font-family: 'Zen Dots';
  font-weight: 400;
}
.orange-plan.epic-plan-information .plans-to-buy__list__epic-plan__description__main-text {
  color: var(--orange-four);
}
.purple-plan.epic-plan-information .plans-to-buy__list__epic-plan__description__main-text {
  color: var(--purple);
}
.plans-to-buy__list__epic-plan__price-buttons-container {
  display: flex;
  justify-content: space-between;
}
.plans-to-buy__list__epic-plan__time {
  font-size: .973rem;
  font-weight: 600;
  display: flex;
}

.plans-to-buy__list__epic-plan__buttons-container {
  margin-top: 2.292em;
}
.plans-to-buy__list__epic-plan__button {
  margin: auto;
  margin-top: 3.125em;
  margin-bottom: 3.542em;
}
.plans-to-buy__list__epic-plan__more-information {
  text-decoration: none;
  // font-size: 1.25rem;
  font-size: .95rem;
  margin-top: 2.34em;
  display: flex;
  align-items: center;
  &__text {
    font-size: .834rem;
    font-weight: 600;
  }
}
.plans-to-buy__list__epic-plan__more-information__arrow {
  margin: 0;
  margin-left: 0.834em;
  width: 0.834em;
  display: flex;
}
.plans-to-buy__list__epic-plan__more-information__arrow svg {
  width: 100%;
  height: 100%;
}

.plans-to-buy__list__epic-plan__price-container {
  display: flex;
  /* align-items: center; */
  margin-top: 0.99em;
  margin-bottom: 2.24em;
}

/* Plan Offer */
.plans-offer-two-sections-container .plans-to-buy__list__epic-plan{
  background-color: transparent;
}
.plans-offer-two-sections-container {
  background-color: var(--gray-100);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  width: min-content;
  height: min-content;
}
.plan-offer .plans-to-buy__list__epic-plan__time {
  margin-bottom: 0;
}
.plan-offer .plans-to-buy__prices-container {
  display: flex;
  color: rgba(0, 0, 0, .17);
  margin-right: 1.32em;
}
.plan-offer .plans-to-buy__list__epic-plan__price {
  position: relative;
}
.plan-offer .plans-to-buy__diagonal-line {
  position: absolute;
  top: 0em;
  right: .2em;
  width: 3.8em;
}
.plans-to-buy__middle-line {
  width: 81.6%;
  height: 1px;
  background-color: var(--turquoise-900);
  margin: 1.667em auto;
}

.plans-to-buy__list__epic-plan__price__offer__line {
  position: absolute;
  width: 1.943em;
}
.plans-to-buy__list__epic-plan__price__offer__arrow {
  margin: 0;
  margin-top: .5em;
  margin-left: 1.26em;
  margin-right: 1.05em;
  width: .84em;
  display: flex;
  height: min-content;
}
.plans-to-buy__list__epic-plan__price__offer__arrow img {
  width: 100%;
  height: auto;
}

.plans-to-buy__list__epic-plan.individual-plan {
  margin-right: 0;
  grid-area: plans-page__individual-plan;
}
.plans-to-buy__list__epic-plan.individual-more-hours-plan {
  grid-area: plans-page__individual-more-hours-plan;
}
.plans-to-buy__list__epic-plan.mentor-plan {
  grid-area: plans-page__mentor-plan;
}
.entrepreneurship-registration-plan {
  grid-area: plans-page__entrepreneurship-registration-plan;
}
.entrepreneurship-technology-registration-plan {
  grid-area: plans-page__entrepreneurship-technology-registration-plan;
}
.entrepreneurship-technology-english-monthly {
  grid-area: plans-page__entrepreneurship-technology-english-monthly;
}
.entrepreneurship-technology-english-quarterly {
  grid-area: plans-page__entrepreneurship-technology-english-quarterly;
}
.english-online-monthly {
  grid-area: plans-page__english-online-monthly;
}
.english-online-quarterly {
  grid-area: plans-page__english-online-quarterly;
}
.entrepreneurship-presential-monthly {
  grid-area: plans-page__entrepreneurship-presential-monthly;
}
.entrepreneurship-technology-presential-monthly {
  grid-area: plans-page__entrepreneurship-technology-presential-monthly;
}
.entrepreneurship-presential-quarterly {
  grid-area: plans-page__entrepreneurship-presential-quarterly;
}
.entrepreneurship-technology-presential-quarterly {
  grid-area: plans-page__entrepreneurship-technology-presential-quarterly
}
.english-presential-monthly {
  grid-area: plans-page__english-presential-monthly;
}
.english-presential-quarterly {
  grid-area: plans-page__english-presential-quarterly;
}
.entrepreneurship-technology-english-presential-monthly {
  grid-area: plans-page__entrepreneurship-technology-english-presential-monthly;
}
.entrepreneurship-technology-english-presential-quarterly {
  grid-area: plans-page__entrepreneurship-technology-english-presential-quarterly;
}
.plans-container__middle-line,
.plans-container__middle-line-2,
.plans-container__middle-line-3,
.plans-container__middle-line-4,
.plans-container__middle-line-5,
.plans-container__middle-line-6,
.plans-container__middle-line-7 {
  width: 83.334em;
  height: 3px;
  background-color: var(--turquoise-900);
}
.plans-container__middle-line {
  grid-area: plans-page__container__middle-line;
}
.plans-container__middle-line-2 {
  grid-area: plans-page__container__middle-line-2;
}
.plans-container__middle-line-3 {
  grid-area: plans-page__container__middle-line-3;
}
.plans-container__middle-line-4 {
  grid-area: plans-page__container__middle-line-4;
}
.plans-container__middle-line-5 {
  grid-area: plans-page__container__middle-line-5;
}
.plans-container__middle-line-6 {
  grid-area: plans-page__container__middle-line-6;
}
.plans-container__middle-line-7 {
  grid-area: plans-page__container__middle-line-7;
}
/* end plans to buy list cards */

@media screen and (max-width: 1024px) {
  /* Plans */
  .plans-to-buy__list {
    flex-direction: column;
  }
  .plans-to-buy__list__epic-plan {
    width: 38.75em;
  }
  .plans-to-buy__list__epic-plan.individual-plan {
    margin-right: 0;
  }
  .plans-to-buy__list__epic-plan__user-icon {
    width: 2.084em;
  }
  .plans-to-buy__list__epic-plan__title-container {
    margin-bottom: .534em;
  }
  .plans-to-buy__list__epic-plan__title {
    font-size: 2.787rem;
  }
  .plans-to-buy__list__call-to-action {
    margin-top: .2em;
  }
  .plans-to-buy__list__epic-plan__description {
    font-size: 1.951rem;
  }
  .plans-to-buy__list__epic-plan__price {
    font-size: 3.344rem;
  }
  .plans-to-buy__list__epic-plan__time {
    font-size: 1.951rem;
  }
  .plans-to-buy__list__epic-plan__more-information {
    margin-top: 3.08em;
  }
  .plans-to-buy__list__epic-plan__more-information__text {
    font-size: 1.673rem;
  }
  .plans-to-buy__list__epic-plan__button {
    font-size: 1.25rem;
  }

  .individual-more-hours-plan .plans-to-buy__list__epic-plan__title-container {
    width: 100%;
  }

  .plans-container__middle-line,
  .plans-container__middle-line-2,
  .plans-container__middle-line-3,
  .plans-container__middle-line-4,
  .plans-container__middle-line-5,
  .plans-container__middle-line-6,
  .plans-container__middle-line-7 {
    width: 100%;
  }
}